/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { SEO } from '../components';
import Main from '../page-components/legal/Privacy';

const Privacy = ({
  data: {
    site: { siteMetadata: meta },
  },
  location: { pathname },
}) => {
  //

  return (
    <article>
      <SEO title="Privacy Policy" description={`${meta.siteName}'s Privacy Policy`} />
      <Main />
    </article>
  );
};

export default Privacy;

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
